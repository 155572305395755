<template>
  <div class="home">
    <div class="header-nav">
      <div class="header-nav-left">
        <div class="grid-content" style="text-align: left">
          <img class="header-img" style src="@/assets/logo.png" alt />
        </div>
        <div>
          <div
            class="grid-content header-content header-tit"
            style="color: #fff; font-size: 2rem; margin-left: 2%; width: 120%"
          >
            如意花
          </div>
        </div>
        <div>
          <div
            class="grid-content header-content"
            style="color: #fff; margin-left: 4%; width: 120%"
          >
            贷款更放心安全
          </div>
        </div>
      </div>

      <div
        class="header-nav-right"
        style="display: flex; justify-content: space-between"
      >
        <div
          class="grid-content header-content"
          style="color: #fff; cursor: pointer"
          @click="shouyeTop"
        >
          首页
        </div>
        <div
          class="grid-content header-content"
          style="color: #fff; cursor: pointer"
          @click="companyFn"
        >
          优势
        </div>
        <div
          class="grid-content header-content"
          style="color: #fff; cursor: pointer"
          @click="appFn"
        >
          步骤
        </div>
        <div
          class="grid-content header-content"
          style="color: #fff; cursor: pointer"
          @click="phoneFn"
        >
          联系我们
        </div>
      </div>
    </div>

    <div class="banner">
      <div style="font-size: 1.75rem">息费透明｜合规经营｜符合国家监管要求</div>
      <div style="font-size: 3rem; margin-top: 4.375rem">最高可借(元)</div>
      <div
        class="amount-animation"
        style="font-size: 8.75rem; margin-top: 3rem"
      >
        200,000
      </div>
      <div style="font-size: 1.75rem; margin-top: 4.375rem">
        1000元用1天｜利息低至0.3元
        <span style="font-size: 1.25rem">(以最终审批利率为准)</span>
      </div>
    </div>

    <el-row class="advantage" id="advantage">
      <div style="font-size: 2.125rem; font-weight: bold; margin-top: 4rem">
        如意花优势
      </div>
      <div>
        <div style="">
          <el-col :span="6">
            <div
              class="advantage-top"
              style="margin: auto; margin-top: 7.75rem"
            >
              <img src="@/assets/img/ys.png" alt />
            </div>
            <div class="advantage-tit">门槛低</div>
            <div class="advantage-p">满25-55周岁有机会申请</div>
          </el-col>
          <el-col :span="6">
            <div
              class="advantage-top"
              style="margin: auto; margin-top: 7.75rem"
            >
              <img src="@/assets/img/zh.png" alt />
            </div>
            <div class="advantage-tit">额度大</div>
            <div class="advantage-p">最高借款额度达20万</div>
          </el-col>
        </div>
        <div>
          <el-col :span="6">
            <div
              class="advantage-top"
              style="margin: auto; margin-top: 7.75rem"
            >
              <img src="@/assets/img/bank.png" alt />
            </div>
            <div class="advantage-tit">到账快</div>
            <div class="advantage-p">最快5分钟到账</div>
          </el-col>
          <el-col :span="6">
            <div
              class="advantage-top"
              style="margin: auto; margin-top: 7.75rem"
            >
              <img src="@/assets/img/lh.png" alt />
            </div>
            <div class="advantage-tit">更灵活</div>
            <div class="advantage-p">最长可分24期 灵活借款</div>
          </el-col>
        </div>
      </div>
    </el-row>

    <el-row class="ys" id="ys">
      <div style="font-size: 2.125rem; font-weight: bold; margin-top: 8.125rem">
        简单
        <span style="color: #2276ff">3&nbsp;</span>步，极速放款
      </div>
      <div class="ys-span">
        <el-col :span="6">
          <div class="loan-tit">第一步</div>
          <div class="loan-h">在线申请</div>
          <div class="loan-p">一分钟在线完成</div>
        </el-col>
        <el-col :span="3">
          <div class="loan-img">
            <img src="@/assets/img/right.png" alt />
          </div>
        </el-col>
        <el-col :span="6">
          <div class="loan-tit">第二步</div>
          <div class="loan-h">获得额度</div>
          <div class="loan-p">极速审批 最高20万额度</div>
        </el-col>
        <el-col :span="3">
          <div class="loan-img">
            <img src="@/assets/img/right.png" alt />
          </div>
        </el-col>
        <el-col :span="6">
          <div class="loan-tit">第三步</div>
          <div class="loan-h">提现到账</div>
          <div class="loan-p">最快当天到账</div>
        </el-col>
      </div>
    </el-row>

    <!-- pc公司介绍模块-->
    <el-row id="company" class="company-Profile">
      <el-col :span="2">&nbsp;</el-col>
      <el-col :span="10">
        <div class="company-img" style="margin-top: 8.125rem">
          <img src="@/assets/img/sh.png" alt />
        </div>
      </el-col>
      <el-col :span="10">
        <div class="company-Profile-content" style="margin-top: 13.6875rem">
          <div class="company-top">
            <div class="phoneApp" style="margin-left: 1rem; margin-top: 4.5rem">
              <div style="color: #333333; font-size: 2.125rem">About Us</div>
              <div style="color: #999999; font-size: 1rem">
                持牌机构 正规平台
              </div>
            </div>
          </div>

          <div
            style="
              margin: 3.4375rem 1.5rem 6.8rem 3.4rem;
              text-align: left;
              color: #131313;
              font-size: 1.2rem;
            "
          >
            成都有情有义科技有限公司,严格按照监管要求,依法合规经营，以高度的社会责任感，立足自身，为客户提供优质服务；以积极的经营态度，谋求转型，响应新形势下的市场需求，具体从引进示例股东、客户对象、经营模式、内部管理以及产品设计等五个方面扎实推进转型进程。
          </div>
        </div>
      </el-col>
      <el-col :span="2"></el-col>
    </el-row>

    <!-- pc端底部备案号区域 -->
    <el-row :gutter="0" id="phoneContent" class="footer">
      <el-col>
        <div>成都有情有义科技有限公司</div>
      </el-col>
      <el-col style="margin-top: 10px">
        <div>上班时间：9:00-18:00(周一到周五)</div>
      </el-col>

      <el-col style="margin-top: 10px">
        <div>联系我们:18880446580(09:00-18:00)</div>
      </el-col>

      <el-col style="margin-top: 10px">
        <div><a href="https://beian.miit.gov.cn/" style="color:#fff">蜀ICP备2024078056号</a></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import "element-ui/lib/theme-chalk/display.css";
export default {
  data() {
    return {};
  },

  methods: {
    shouyeTop() {
      document.getElementById("banner").scrollIntoView();
    },

    companyFn() {
      document.getElementById("advantage").scrollIntoView();
    },

    appFn() {
      document.getElementById("ys").scrollIntoView();
    },
    // 联系我们
    phoneFn() {
      document.getElementById("phoneContent").scrollIntoView();
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  .header-nav {
    background-color: #0260c9;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 10%;
    .header-img {
      width: 3rem;
      height: 3rem;
    }

    .header-nav-left {
      display: flex;
      align-items: center;
    }

    .header-nav-right {
      display: flex;
      width: 20rem;
      justify-content: space-between;
    }
  }

  .banner {
    @keyframes scale {
      0% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }

      100% {
        transform: scale(1);
      }
    }

    .amount-animation {
      animation: scale 1s infinite;
    }

    background-color: #2276ff;
    color: #fff;
    text-align: center;
    padding: 2rem;
  }

  .advantage {
    .advantage-tit {
      font-size: 1rem;
      font-weight: 700;
      margin-top: 1rem;
    }

    .advantage-p {
      font-size: 1.2rem;
      font-weight: 700;
      margin-top: 1rem;
    }
  }

  .ys-span {
    margin-top: 8rem;
    display: flex;
    align-items: center;

    .loan-tit {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .loan-h {
      margin: 0.5rem;
    }

    .loan-img {
      img {
        width: 4rem;
      }
    }
  }

  .company-img {
    img {
      width: 30rem;
    }
  }

  .footer {
    margin-top: 6rem;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
    background-color: #333333;
  }
}
</style>
