/*
 * @Author: 恒 624129583@qq.com
 * @Date: 2024-05-12 10:55:48
 * @LastEditors: 恒 624129583@qq.com
 * @LastEditTime: 2024-05-12 10:58:54
 * @FilePath: \ruyihua-official-website\src\store\rem.js
 * @Description: 
 */

! function(n) {
    var e = n.document,
        t = e.documentElement,
        i = 720,
        d = i / 16,
        o = "orientationchange" in n ? "orientationchange" : "resize",
        a = function() {
            var n = t.clientWidth || 320;
            n > 720 && (n = 720);
          
            t.style.fontSize = n / d + "px"
          console.log(t.style.fontSize)
        };
    e.addEventListener && (n.addEventListener(o, a, !1), e.addEventListener("DOMContentLoaded", a, !1))

}(window);