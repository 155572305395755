/*
 * @Author: 恒 624129583@qq.com
 * @Date: 2024-05-12 10:55:48
 * @LastEditors: 恒 624129583@qq.com
 * @LastEditTime: 2024-05-12 10:58:40
 * @FilePath: \ruyihua-official-website\src\router\index.js
 * @Description: 
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
